import React, { ProviderProps, ReactNode } from 'react';
import { QueryClientProvider } from 'react-query';
import { QueryClientProvider as QueryV5ClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import guardianQueryClient, { guardianQueryV5Client } from '@src/apiFetch/guardianQueryClient';
import { AuthProvider } from '@descope/react-sdk';
import getConfig from '@src/util/getConfig';
import ZipyProvider from '@src/components/thirdPartyProviders/Zipy/ZipyProvider';
import SentryProvider from '@src/components/thirdPartyProviders/Sentry/SentryProvider';
import {
    BroadcastChannelContext,
    bc,
} from '@src/context/BroadcastChannelContext';

type GlobalProviderProps = {
    // eslint-disable-next-line react/no-unused-prop-types
    isSSR: boolean;
    children: ReactNode | JSX.Element;
};

const EmptyWrapper = ({ children }: any) => {
    return children;
};

export const RootProvider = ({ isSSR, children }: GlobalProviderProps) => {
    const config = getConfig();
    const BroadcastProvider: React.FC<ProviderProps<BroadcastChannel>> = isSSR
        ? EmptyWrapper
        : BroadcastChannelContext.Provider;
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <BroadcastProvider value={bc}>
            <QueryClientProvider client={guardianQueryClient}>
                <QueryV5ClientProvider client={guardianQueryV5Client}>
                    <Helmet
                        bodyAttributes={{ class: 'font-custom' }}
                        link={[
                            {
                                rel: 'icon',
                                type: 'image/svg',
                                href: config.favicon_url,
                            },
                        ]}
                    >
                        {/* Hubspot Embed Script */}
                        <script
                            type="text/javascript"
                            id="hs-script-loader"
                            async
                            defer
                            src="//js-na1.hs-scripts.com/23684445.js"
                        />
                        <script
                            async
                            src={`https://www.googletagmanager.com/gtag/js?id=${config.google_analytics_id}`}
                        />
                        <script>
                            {` window.dataLayer = window.dataLayer || []
                                function gtag() {
                                    dataLayer.push(arguments)
                                }
                                gtag('js', new Date())
                                gtag('config', '${config.google_analytics_id}')`}
                        </script>
                    </Helmet>
                    {children}
                </QueryV5ClientProvider>
            </QueryClientProvider>
        </BroadcastProvider>
    );
};

export const PageProvider = ({ children }: GlobalProviderProps) => {
    const config = getConfig();
    return (
        <>
            {/* @ts-ignore */}
            <AuthProvider projectId={config.descope_project_id}>
                <ZipyProvider />
                <SentryProvider />
                {children}
            </AuthProvider>
            <Toaster toastOptions={{ duration: 5000 }} />
        </>
    );
};
